<template>
  <svg
    id="8b288b33-7efa-4b51-b924-a7cfda207295"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 37 37"><path
      d="M7.37,0H29.63A7.38,7.38,0,0,1,37,7.38V29.63A7.37,7.37,0,0,1,29.63,37H7.38A7.37,7.37,0,0,1,0,29.63V7.37A7.37,7.37,0,0,1,7.37,0Z"
      class="icon-wrapper__template"
      :fill="color" /><path
        d="M13.9,13a2.59,2.59,0,0,0-2-2.07l-.06,0-.12,0-.33,0h-.08l-.33,0h-.12A2.58,2.58,0,0
        ,0,8.79,13a1.51,1.51,0,0,0,0,3h5a1.51,1.51,0,0,0,0-3Zm0,2.15h-5a.65.65,0,0,1
        ,0-1.29h.36a.43.43,0,0,0,.43-.43,1.72,1.72,0,0,1,1.26-1.65l.12,0a1.73,1.73,0
        ,0,1,.34,0,1.75,1.75,0,0,1,.33,0l.21.06.09,0a1.74,1.74,0,0,1,.51.32h0a1.71
        ,1.71,0,0,1,.6,1.29.43.43,0,0,0,.43.43h.36a.65.65,0,0,1,.46.19.64.64,0,0,1-.46,1.1Z"
        transform="translate(0 0)"
        fill="#fff" /><path
          d="M29.93,28.51H26.48V27.12l1-1a.46.46,0,0,0-.66-.66l-.31.31V23.44a4.12,4.12,0,0,0
          ,3.75-4.09s0-.08,0-.13a6.66,6.66,0,0,0-2-4.63,9.09,9.09,0,0,0-2-1.58.46.46,0
          ,0,0-.44,0,9.09,9.09,0,0,0-2,1.58,6.66,6.66,0,0,0-2,4.63s0,.08,0,.13a4.81,4.81
          ,0,0,0,.13,1,2.5,2.5,0,0,0-.14,4.93v1.2l0,0a.46.46,0,1,0-.66.66l.71.71v.69H17.38V26.93H18a.46.46
          ,0,0,0,.46-.46V24.74a.46.46,0,0,0-.46-.46h-1.9v-1.4h1.3a.46.46,0,0,0,.46-.46V19.67a.46.46
          ,0,0,0-.46-.46H8.83a.46.46,0,0,0-.46.46v2.74a.46.46,0,0,0,.46.46h1.35v1.4h-2a.46.46,0,0
          ,0-.46.46v1.72a.46.46,0,0,0,.46.46h.6v1.58H7.06a.46.46,0,0,0,0,.93H29.93a.46.46,0,0,0
          ,0-.93ZM9.29,21.94V20.13h7.62v1.81h-.84v-.37a.46.46,0,0,0-.93,0v.37h-4v-.37a.46.46,0
          ,0,0-.93,0v.37Zm5.86.93v1.4h-4v-1.4ZM8.69,26v-.79h8.83V26Zm7.76,2.51H9.76V26.93h6.69Zm5.75-5.73a.46.46
          ,0,0,0-.46.46v1.09a1.57,1.57,0,0,1,.46-3.07,3.62,3.62,0,0,0,.61.87,4.37,4.37,0,0,0,1,.75,1.57,1.57
          ,0,0,1-1.1,1.44V23.25A.46.46,0,0,0,22.2,22.78Zm3.35,4.14h0v1.58H22.67V25.3a2.51,2.51,0,0,0,2-2
          ,4.58,4.58,0,0,0,.89.17ZM26,16.39a.46.46,0,0,0-.46.46V19l-.48-.49a.46.46,0,0,0-.66.65l1.14
          ,1.15v2.21a3.17,3.17,0,0,1-2.87-3.18s0-.07,0-.11a5.67,5.67,0,0,1,1.74-4A8.67,8.67,0,0,1,26
          ,14c.77.49,3.31,2.33,3.31,5.26,0,0,0,.07,0,.11a3.17,3.17,0,0,1-2.82,3.17V20.29l1.1-1.16a.46.46
          ,0,0,0-.67-.64l-.42.44V16.86A.46.46,0,0,0,26,16.39Z"
          transform="translate(0 0)"
          fill="#fff" /><path
            d="M21.28,8.62a2.59,2.59,0,0,0-2-2.07l-.06,0-.12,0-.33,0h-.08l-.33,0h-.12a2.58,2.58,0,0
            ,0-2.12,2.12,1.51,1.51,0,0,0,0,3h5a1.51,1.51,0,0,0,0-3Zm0,2.15h-5a.65.65,0,0,1
            ,0-1.29h.36A.43.43,0,0,0,17,9.05,1.72,1.72,0,0,1,18.26,7.4l.12,0a1.73,1.73,0,0
            ,1,.34,0,1.75,1.75,0,0,1,.33,0l.21.06.09,0a1.74,1.74,0,0,1,.51.32h0a1.71,1.71
            ,0,0,1,.6,1.29.43.43,0,0,0,.43.43h.36a.65.65,0,0,1,.46.19.64.64,0,0,1-.46,1.1Z"
            transform="translate(0 0)"
            fill="#fff" /><path
              d="M20,7.39a1.72,1.72,0,1,1,2.2,2.53l.48.67a2.54,2.54,0,1,0-3.28-3.77Z"
              transform="translate(0 0)"
              fill="#fff" /></svg>
</template>
<script>
import ProjectIconTemplate from '@/mixins/ProjectIconTemplate';
export default {
  mixins: [ProjectIconTemplate],
};
</script>